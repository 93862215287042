import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import ArrowImg from '@/assets/images/common/header/arrow.inline.svg';
import LocaleImg from '@/assets/images/common/header/locale.inline.svg';
import { i18next, switchLanguage } from '@/i18n';
type Language = 'en-US' | 'zh';
export function LanguageSwitch() {
  const [isOpen, setOpen] = useState(false);
  const [activeLng, setActiveLng] = useState<Language>();
  useEffect(() => {
    setActiveLng(i18next.language as Language);
  }, [isOpen]);
  return (
    <div
      className="relative hidden h-16 items-center px-[6px] lg:flex lg:h-[88px]"
      onMouseEnter={() => {
        setOpen(true);
      }}
      onClick={() => {
        setOpen(!isOpen);
      }}
      onMouseLeave={() => {
        setOpen(false);
      }}
    >
      <button
        className={cn(
          'flex items-center gap-x-1 rounded-xl bg-[#242333] px-3 py-2'
        )}
      >
        <LocaleImg className="!w-6" />
        <span className="leading-6 sm:text-lg">
          {i18next.language === 'zh' ? '简体中文' : 'EN'}
        </span>
        <ArrowImg
          className={cn(
            '!w-3 duration-200',
            isOpen ? 'rotate-180' : 'rotate-0'
          )}
        />
      </button>
      <div
        className={cn(
          'absolute right-0 top-[70px] flex w-[175px] flex-col justify-between overflow-hidden rounded-lg border-solid border-[#292738] bg-[#0E0D1B] text-base leading-7 text-[#EEFFFC] duration-200 sm:left-[50%] sm:right-auto sm:-translate-x-[50%]',
          isOpen ? 'h-[103px] border-[1px]' : 'h-0 border-none'
        )}
      >
        <button
          onMouseEnter={() => {
            setActiveLng('en-US');
          }}
          onClick={() => {
            switchLanguage('en-US');
            setOpen(false);
          }}
          className={cn(
            'mx-[10px] my-[2px] mt-[14px] rounded-lg px-[5px] py-1 text-center',
            activeLng === 'en-US' && 'bg-[#201e31]'
          )}
        >
          English
        </button>
        <button
          onMouseEnter={() => {
            setActiveLng('zh');
          }}
          onClick={() => {
            switchLanguage('zh');
            setOpen(false);
          }}
          className={cn(
            'mx-[10px] my-[2px] mb-[14px] rounded-lg px-[5px] py-1 text-center',
            activeLng === 'zh' && 'bg-[#201e31]'
          )}
        >
          简体中文
        </button>
      </div>
    </div>
  );
}
