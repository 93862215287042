import cn from 'classnames';
import React from 'react';

import { useTranslation } from '@/i18n';

import { TitleLine } from './TitleLine';

const partners = [
  {
    alt: 'matrix labs',
    href: '',
    bgUrl: '/images/partners/matrix_labs.png'
  },
  {
    alt: 'matrix world',
    href: '',
    bgUrl: '/images/partners/matrix_world.png'
  },
  {
    alt: 'ethereum',
    href: '',
    bgUrl: '/images/partners/ethereum.png'
  },
  {
    alt: 'bnbchain',
    href: '',
    bgUrl: '/images/partners/bnbchain.png'
  },
  {
    alt: 'polygon',
    href: '',
    bgUrl: '/images/partners/polygon.png'
  },
  {
    alt: 'astar',
    href: '',
    bgUrl: '/images/partners/astar.png'
  },
  {
    alt: 'sui',
    href: '',
    bgUrl: '/images/partners/sui.png'
  },
  {
    alt: 'flow',
    href: '',
    bgUrl: '/images/partners/flow.png'
  },
  {
    alt: 'aptos',
    href: '',
    bgUrl: '/images/partners/aptos.png'
  },
  {
    alt: 'conflux',
    href: '',
    bgUrl: '/images/partners/conflux.png'
  },
  {
    alt: 'nervos',
    href: '',
    bgUrl: '/images/partners/nervos.png'
  },
  {
    alt: 'dfinity',
    href: '',
    bgUrl: '/images/partners/dfinity.png'
  },
  {
    alt: 'xdc',
    href: '',
    bgUrl: '/images/partners/xdc.png'
  },
  {
    alt: 'antchain',
    href: '',
    bgUrl: '/images/partners/antchain.png'
  },
  {
    alt: 'fiscobcos',
    href: '',
    bgUrl: '/images/partners/fiscobcos.png'
  },
  {
    alt: 'iris',
    href: '',
    bgUrl: '/images/partners/iris.png'
  },
  {
    alt: 'wanxiang blockchain',
    href: '',
    bgUrl: '/images/partners/wanxiang.png'
  },
  {
    alt: 'fund',
    href: '',
    bgUrl: '/images/partners/fund.png'
  },
  {
    alt: 'order',
    href: '',
    bgUrl: '/images/partners/order.png'
  },
  {
    alt: 'ant group',
    href: '',
    bgUrl: '/images/partners/ant_group.png'
  },
  {
    alt: 'y2z',
    href: '',
    bgUrl: '/images/partners/y2z.png'
  },
  {
    alt: 'hash key',
    href: '',
    bgUrl: '/images/partners/hashkey.png'
  },
  {
    alt: 'ubc',
    href: '',
    bgUrl: '/images/partners/ubc.png'
  },
  {
    alt: 'alberta',
    href: '',
    bgUrl: '/images/partners/alberta.png'
  },
  {
    alt: 'lion',
    href: '',
    bgUrl: '/images/partners/lion.png'
  },
  {
    alt: 'alibaba cloud',
    href: '',
    bgUrl: '/images/partners/alibaba_cloud.png'
  },
  {
    alt: 'aws',
    href: '',
    bgUrl: '/images/partners/aws.png'
  },
  {
    alt: 'unchain',
    href: '',
    bgUrl: '/images/partners/unchain.png'
  },
  {
    alt: 'seedao',
    href: '',
    bgUrl: '/images/partners/seedao.png'
  },
  {
    alt: 'colorful nation',
    href: '',
    bgUrl: '/images/partners/chain_cloud.png'
  },
  {
    alt: 'rebase',
    href: '',
    bgUrl: '/images/partners/rebase.png'
  },
  {
    alt: 'coffee with crypto',
    href: '',
    bgUrl: '/images/partners/coffee.png'
  },
  {
    alt: 'mask',
    href: '',
    bgUrl: '/images/partners/mask.png'
  },
  {
    alt: 'phanta bear',
    href: '',
    bgUrl: '/images/partners/phanta_bear.png'
  },
  {
    alt: 'theirsverse',
    href: '',
    bgUrl: '/images/partners/theirsverse.png'
  }
];
export function SectionPartners() {
  const { t } = useTranslation();
  return (
    <div className="relative min-h-[600px] overflow-x-hidden">
      <div className="page-layout">
        <TitleLine
          title={t('home.partners.title')}
          className="mb-12 mt-0 sm:mb-20 sm:mt-16"
          align="center"
        />
        <div className="grid grid-cols-2 gap-x-3 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-[33px] md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
          {partners.map((partner, index) => (
            <div
              key={index}
              className={cn(
                'w-full overflow-hidden rounded-[14px] bg-cover bg-center',
                partner.bgUrl ? 'aspect-[2.7485]' : '-z-10 h-[1px] opacity-0'
              )}
              style={{
                backgroundImage: `url('${partner.bgUrl}')`
              }}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
}
