// import { GA4_TRACK_ID } from 'gatsby-env-variables';
import React from 'react';

// import ReactGA from 'react-ga4';
import { I18nextProvider } from '@/i18n';

interface RootElementProps {
  children: JSX.Element;
}

// ReactGA.initialize(GA4_TRACK_ID);

export function RootElement(props: RootElementProps) {
  const { children } = props;

  return <I18nextProvider>{children}</I18nextProvider>;
}
