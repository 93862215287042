import cn from 'classnames';
import { navigate } from 'gatsby';
import React from 'react';

import DiscordIcon from '@/assets/images/common/footer/discord.inline.svg';
import FaceBookIcon from '@/assets/images/common/footer/facebook.inline.svg';
import GithubIcon from '@/assets/images/common/footer/github.inline.svg';
import MediumIcon from '@/assets/images/common/footer/medium.inline.svg';
import TelegramIcon from '@/assets/images/common/footer/telegram.inline.svg';
import TwitterIcon from '@/assets/images/common/footer/twitter.inline.svg';
import YoutubeIcon from '@/assets/images/common/footer/youtube.inline.svg';
import EduLogo from '@/assets/images/logo.inline.svg';
import { useTranslation } from '@/i18n';
const socials = [
  {
    icon: MediumIcon,
    link: 'https://medium.com/white-matrix'
  },
  {
    icon: GithubIcon,
    link: 'https://github.com/WhiteMatrixTech'
  },
  {
    icon: FaceBookIcon,
    link: 'https://www.facebook.com/MatrixDapp/'
  },
  {
    icon: TwitterIcon,
    link: 'https://twitter.com/ChainIde'
  },
  {
    icon: TelegramIcon,
    link: 'https://t.me/ChainIDEdevelopers'
  },
  {
    icon: YoutubeIcon,
    link: 'https://www.youtube.com/channel/UCgvPUHayWfxAGiJCI2xOzNg'
  },
  {
    icon: DiscordIcon,
    link: 'https://discord.com/invite/qv8M29pYwX'
  }
];

const links = [
  {
    titleTransKey: 'menu.learn',
    subs: [
      {
        subtitleTransKey: 'menu.education',
        href: 'https://edu.chainide.com'
      },
      {
        subtitleTransKey: 'menu.courses',
        href: 'https://edu.chainide.com/courses'
      },
      {
        subtitleTransKey: 'menu.build',
        href: ''
      },
      {
        subtitleTransKey: 'menu.studio',
        href: 'http://chainide.com/'
      },
      {
        subtitleTransKey: 'menu.ext',
        href: ' '
      }
    ]
  },

  {
    titleTransKey: 'menu.eco',
    subs: [
      {
        subtitleTransKey: 'menu.events',
        href: 'https://chainide.com/events/'
      },
      {
        subtitleTransKey: 'menu.bootcamp',
        href: 'https://chainide.com/events/bootcamp'
      },
      {
        subtitleTransKey: 'menu.hackathon',
        href: 'https://chainide.com/events/hackathon'
      },
      {
        subtitleTransKey: 'menu.workshop',
        href: 'https://chainide.com/events/workshop-seminar'
      },
      {
        subtitleTransKey: 'menu.summit',
        href: 'https://chainide.com/events/developer-summit'
      }
    ]
  },
  {
    titleTransKey: 'menu.community',
    subs: [
      {
        subtitleTransKey: 'menu.forum',
        href: 'https://forum.chainide.com/'
      },
      {
        subtitleTransKey: 'menu.discord',
        href: 'https://discord.com/invite/qv8M29pYwX'
      },
      {
        subtitleTransKey: 'menu.twitter',
        href: 'https://twitter.com/ChainIde'
      },
      {
        subtitleTransKey: 'menu.telegram',
        href: 'https://t.me/ChainIDEdevelopers'
      },
      {
        subtitleTransKey: 'menu.facebook',
        href: 'https://www.facebook.com/MatrixDapp/'
      }
    ]
  },
  {
    titleTransKey: 'menu.resources',
    subs: [
      {
        subtitleTransKey: 'menu.blog',
        href: 'https://medium.com/white-matrix'
      },
      {
        subtitleTransKey: 'menu.guides',
        href: 'https://chainide.gitbook.io/chainide-chinese/chainide/1.-ethereum-ide'
      },
      {
        subtitleTransKey: 'menu.docs',
        href: 'https://chainide.gitbook.io/chainide-english-1/'
      },
      {
        subtitleTransKey: 'menu.videos',
        href: 'https://www.youtube.com/channel/UCgvPUHayWfxAGiJCI2xOzNg'
      },
      {
        subtitleTransKey: 'menu.osc',
        href: 'https://github.com/WhiteMatrixTech'
      }
    ]
  },
  {
    titleTransKey: 'menu.company',
    subs: [
      {
        subtitleTransKey: 'menu.about',
        href: 'https://whitematrix.io/?lang=en'
      },
      {
        subtitleTransKey: 'menu.contact',
        href: 'https://t.me/ChainIDEdevelopers'
      }
    ]
  }
];
export function Footer() {
  const { t } = useTranslation();
  return (
    <div className="relative mt-20 w-full sm:mt-40">
      <div className="absolute inset-0 hidden sm:block">
        <div className='page-layout h-full w-full origin-top bg-[url("/images/bg_footer.png")] bg-contain bg-[50%_120%] bg-no-repeat'></div>
        <div className="absolute inset-0 bg-[linear-gradient(180deg,rgba(0,0,0,0.00)_0%,#000_100%)]"></div>
      </div>
      <footer
        className={cn(
          'page-layout relative !min-h-[0px] pb-8 pt-[98px] text-[#97A2B8]'
        )}
      >
        <div className="flex flex-wrap items-start justify-between border-b-[1px] border-solid border-[#424b59ff] pb-8">
          <div className="pb-10">
            <EduLogo className="mb-3 origin-left scale-[0.75] lg:scale-[1]" />
            <div className="flex items-center gap-x-3">
              {socials.map((social) => (
                <a
                  href={social.link}
                  target="_blank"
                  key={social.link}
                  className="flex h-8 w-8 items-center justify-center rounded duration-200"
                  rel="noreferrer"
                >
                  <social.icon className="scale-[1.5] text-[#6D758B] hover:text-[#95B1E8]" />
                </a>
              ))}
            </div>
          </div>
          <div className="flex flex-row flex-wrap gap-x-[28px] xl:gap-x-[56px]">
            {links.map((item) => (
              <div
                className={cn(
                  'mb-6 flex w-[160px] flex-col gap-y-3 sm:mb-[18px] sm:w-auto sm:gap-y-[18px]'
                )}
                key={item.titleTransKey}
              >
                <div className="font-medium text-white">
                  {t(item.titleTransKey)}
                </div>
                {item.subs.map((sub) => (
                  <div
                    key={sub.subtitleTransKey}
                    onClick={() => {
                      if (sub.href) {
                        if (sub.href.includes('http')) {
                          window.open(sub.href, '_blank');
                        }
                        if (sub.href.startsWith('/')) {
                          void navigate(sub.href);
                        }
                      }
                    }}
                    className={cn(
                      sub.href === ''
                        ? 'font-medium text-white'
                        : 'cursor-pointer text-sm leading-[24px] hover:text-white'
                    )}
                  >
                    {t(sub.subtitleTransKey)}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <p className="my-4 text-center text-base">
          © 2023 White Matrix Ltd. | All rights reserved
        </p>
        <div className="flex items-center justify-center gap-x-5 text-sm">
          <a
            href="/"
            className="block h-5 w-[100px] text-center leading-5 duration-200 hover:font-medium hover:text-white"
          >
            {t('footer.tos')}
          </a>
          <a
            href="/"
            className="block h-5 w-[100px] text-center leading-5  duration-200 hover:font-medium hover:text-white"
          >
            {t('footer.privacy')}
          </a>
        </div>
      </footer>
    </div>
  );
}
