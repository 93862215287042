import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useInView } from 'react-intersection-observer';

import studioAILottie from '@/assets/lottie/studio_ai.json';
import studioMultiChainLottie from '@/assets/lottie/studio_multi_chain.json';
import studioPluginLottie from '@/assets/lottie/studio_plugins.json';
import { Button } from '@/components';
import { LottieInView } from '@/components/common/LottieInView';
import { useTranslation } from '@/i18n';
import { useHeaderHeight } from '@/utils/hooks';

import { DynamicCodeBlock } from './DynamicCodeBlock';
import { TitleLine } from './TitleLine';
export function SectionStudio() {
  const { t } = useTranslation();
  const [isMounted, setMounted] = useState(false);
  const { headerHeightValue } = useHeaderHeight();
  useEffect(() => {
    setMounted(true);
  }, []);

  const leftRef = useRef<HTMLDivElement | null>(null);

  const firstElementRef = useRef<HTMLDivElement | null>(null);

  const { ref: secondElementRef, inView: isSecondElementInView } = useInView();
  const { ref: secondElementContentRef, inView: isSecondElementContentInView } =
    useInView();
  const { ref: thirdElementRef, inView: isThirdElementInView } = useInView();
  const { ref: fourthElementRef, inView: isFourthElementInView } = useInView();
  const { ref: lastElementRef, inView: isLastElementInView } = useInView();

  const elements = useMemo(
    () => [
      {
        title: t('home.studio.ai.title'),
        desc: t('home.studio.ai.desc'),
        buttonLink: '',
        element: <LottieInView animationData={studioAILottie} />,
        ref: firstElementRef
      },
      {
        title: t('home.studio.code.title'),
        desc: t('home.studio.code.desc'),
        buttonLink: '',
        element: (
          <div className="w-full max-w-[626px]">
            <DynamicCodeBlock />
          </div>
        ),
        ref: secondElementRef
      },
      {
        title: t('home.studio.multi.title'),
        desc: t('home.studio.multi.desc'),
        buttonLink: '',
        element: <LottieInView animationData={studioMultiChainLottie} />,
        ref: thirdElementRef
      },
      {
        title: t('home.studio.plugin.title'),
        desc: t('home.studio.plugin.desc'),
        buttonLink: '',
        element: <LottieInView animationData={studioPluginLottie} />,
        ref: fourthElementRef
      }
    ],
    [t, secondElementRef, thirdElementRef, fourthElementRef]
  );

  const [activeElementIndex, setActiveElementIndex] = useState(0);
  useEffect(() => {
    let currentIndex = 0;
    if (isFourthElementInView) {
      currentIndex = 3;
    } else if (isThirdElementInView) {
      currentIndex = 2;
    } else if (isSecondElementContentInView) {
      currentIndex = 1;
    }
    setActiveElementIndex(currentIndex);
  }, [
    isFourthElementInView,
    isSecondElementContentInView,
    isSecondElementInView,
    isThirdElementInView
  ]);

  const leftStyle = useMemo(() => {
    if (!leftRef.current || !isMounted) return;
    const leftContentHeight = leftRef.current.offsetHeight;
    if (isLastElementInView) {
      return {
        position: 'absolute',
        bottom: `calc(((100vh - ${headerHeightValue}px - ${leftContentHeight}px) / 2) + 1px + ${
          headerHeightValue / 2
        }px)`,
        top: 'auto'
      };
    }

    const isOtherInView =
      isSecondElementInView || isThirdElementInView || isFourthElementInView;
    return {
      position: isOtherInView ? 'fixed' : 'absolute',
      top: isOtherInView
        ? `calc(((100vh - ${headerHeightValue}px - ${leftContentHeight}px) / 2) + ${headerHeightValue}px)`
        : `calc((100vh - ${headerHeightValue}px - ${leftContentHeight}px) / 2)`
    };
  }, [
    isMounted,
    isLastElementInView,
    isSecondElementInView,
    isThirdElementInView,
    isFourthElementInView,
    headerHeightValue
  ]);

  return (
    <div className="page-layout flex min-h-[calc(100vh_-_88px)] flex-col">
      {/* pc */}
      <div className="relative hidden h-full w-full flex-1 items-center justify-between gap-x-[10vw] overflow-y-hidden sm:flex">
        <div ref={leftRef} style={leftStyle as CSSProperties}>
          <TitleLine
            title={t('home.studio.title')}
            className="mb-12 mt-10 sm:mb-20 sm:mt-0"
            type="coding"
            align="left"
          />
          <div className="w-[calc(100%_-_32px)] sm:max-w-[45vw] lg:w-[471px] lg:max-w-none">
            <h3 className="mb-4 mt-6 text-lg font-semibold sm:text-2xl">
              {elements[activeElementIndex].title}
            </h3>
            <p className="mb-8 text-sm text-[#9591AB] sm:text-base">
              {elements[activeElementIndex].desc}
            </p>
            <Button
              text={t('home.studio.button.text')}
              className="h-11 w-[246px]"
              onClick={() => {
                window.open('https://chainide.com/', '_blank');
              }}
            />
          </div>
        </div>
        <div className="ml-[50%] w-1/2">
          {elements.map((item, index) => (
            <div
              key={item.title}
              ref={index === 1 ? item.ref : undefined}
              className="flex w-full items-center justify-start"
              style={{
                height: `calc(100vh - ${headerHeightValue}px)`
              }}
            >
              <div
                ref={
                  index > 1
                    ? item.ref
                    : index === 1
                    ? secondElementContentRef
                    : undefined
                }
              >
                {item.element}
              </div>
            </div>
          ))}
          <div ref={lastElementRef} className="h-[1px] w-full"></div>
        </div>
      </div>
      {/* mobile */}
      <div className="block sm:hidden">
        <TitleLine
          title={t('home.studio.title')}
          className="mb-12 mt-16"
          type="coding"
          align="left"
        />
        {elements.map((item) => (
          <div key={item.title}>
            <h3 className="mb-4 mt-6 text-lg font-semibold">{item.title}</h3>
            <p className="mb-8 text-sm text-[#9591AB]">{item.desc}</p>
            <div className="mt-6 block min-h-[220px] w-full">
              {item.element}
            </div>
          </div>
        ))}
        <Button
          text={t('home.studio.button.text')}
          className="mt-10 h-11 w-[246px]"
          onClick={() => {
            window.open('https://chainide.com/', '_blank');
          }}
        />
      </div>
    </div>
  );
}
