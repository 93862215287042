import cn from 'classnames';
import React from 'react';

import { useTranslation } from '@/i18n';

import { TitleLine } from './TitleLine';

const cards = [
  {
    titleTransKey: 'home.future.membership.title',
    descTransKey: 'home.future.membership.desc',
    bgUrl: '/images/sixth_card_bg1.png'
  },
  {
    titleTransKey: 'home.future.marketplace.title',
    descTransKey: 'home.future.marketplace.desc',
    bgUrl: '/images/sixth_card_bg2.png'
  },
  {
    titleTransKey: 'home.future.dao.title',
    descTransKey: 'home.future.dao.desc',
    bgUrl: '/images/sixth_card_bg3.png'
  },
  {
    titleTransKey: 'home.future.bounty.title',
    descTransKey: 'home.future.bounty.desc',
    bgUrl: '/images/sixth_card_bg4.png'
  }
];

export function SectionFuture() {
  const { t } = useTranslation();
  return (
    <div className="page-layout min-h-[600px]">
      <TitleLine
        title={t('home.future.title')}
        className="mb-12 mt-16 sm:mb-20 sm:mt-36"
        type="future"
        align="left"
      />
      <div className="flex flex-wrap justify-between gap-6">
        {cards.map((card) => (
          <div
            key={card.titleTransKey}
            className={cn(
              'flex aspect-[2.668] w-full flex-col justify-end overflow-hidden rounded-2xl bg-[#11121c] bg-cover px-4 pb-3 sm:px-8 sm:pb-8 lg:w-[calc((100%_-_24px)_/_2)] lg:max-w-none'
            )}
            style={{
              backgroundImage: `url('${card.bgUrl}')`
            }}
          >
            <h3 className="mb-2 text-lg font-medium leading-normal sm:mb-3 sm:text-[32px]">
              {t(card.titleTransKey)}
            </h3>
            <p className="text-xs leading-normal text-[#9591AB] sm:line-clamp-2 sm:text-base">
              {t(card.descTransKey)}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
