import cn from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

import EduLogo from '@/assets/images/logo.inline.svg';
import { useHeaderHeight, usePageScrolled } from '@/utils/hooks';

import { LanguageSwitch } from './LanguageSwitch';
import { MobileMenu } from './MobileMenu';
import { PCMenu } from './PCMenu';

interface HeaderProps {
  className?: string;
}

export function Header(props: HeaderProps) {
  const { className } = props;
  const { headerHeightCls } = useHeaderHeight();
  const isScrolled = usePageScrolled();
  return (
    <header
      aria-label="ChainIDE Homepage Header"
      className={cn(
        'sticky inset-x-0 top-0 z-50',
        className,
        isScrolled ? 'bg-[#0D1018]' : 'bg-opacity-0'
      )}
    >
      <div
        className={cn(
          'page-layout flex items-center justify-between font-Roboto sm:gap-4 lg:gap-8',
          headerHeightCls
        )}
      >
        <Link to="/">
          <EduLogo className="!w-[97px] shrink-0 lg:!w-[164px]" />
        </Link>
        <div className="flex items-center gap-x-[100px] text-white 2xl:gap-x-[134px]">
          <PCMenu />
          <div className="flex items-center">
            <LanguageSwitch />
            <MobileMenu />
          </div>
        </div>
      </div>
    </header>
  );
}
