import cn from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useCallback, useMemo, useState } from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Button } from '@/components/common/Button';
import { useTranslation } from '@/i18n';
import { useSize } from '@/utils/hooks';

import { TitleLine } from './TitleLine';

interface ITabImg {
  url: string;
  href?: string;
}
interface ITab {
  titleTransKey: string;
  descTransKey: string;
  buttonHref?: string;
  topImgs: ITabImg[];
  bottomImgs: ITabImg[];
}
const tabs: ITab[] = [
  {
    titleTransKey: 'home.events.bootcamp.title',
    descTransKey: 'home.events.bootcamp.desc',
    buttonHref: '',
    topImgs: [
      {
        url: '/images/events/bootcamp/top_1.png',
        href: ''
      },
      {
        url: '/images/events/bootcamp/top_2.png',
        href: ''
      },
      {
        url: '/images/events/bootcamp/top_3.png',
        href: ''
      },
      {
        url: '/images/events/bootcamp/top_4.png',
        href: ''
      }
    ],
    bottomImgs: [
      {
        url: '/images/events/bootcamp/bottom_1.png',
        href: ''
      },
      {
        url: '/images/events/bootcamp/bottom_2.png',
        href: ''
      },
      {
        url: '/images/events/bootcamp/bottom_3.png',
        href: ''
      },
      {
        url: '/images/events/bootcamp/bottom_4.png',
        href: ''
      }
    ]
  },
  {
    titleTransKey: 'home.events.workshop.title',
    descTransKey: 'home.events.workshop.desc',
    buttonHref: '',
    topImgs: [
      {
        url: '/images/events/workshop/top_1.png',
        href: ''
      },
      {
        url: '/images/events/workshop/top_2.png',
        href: ''
      },
      {
        url: '/images/events/workshop/top_3.png',
        href: ''
      },
      {
        url: '/images/events/workshop/top_4.png',
        href: ''
      }
    ],
    bottomImgs: [
      {
        url: '/images/events/workshop/bottom_1.png',
        href: ''
      },
      {
        url: '/images/events/workshop/bottom_2.png',
        href: ''
      },
      {
        url: '/images/events/workshop/bottom_3.png',
        href: ''
      },
      {
        url: '/images/events/workshop/bottom_4.png',
        href: ''
      }
    ]
  },
  {
    titleTransKey: 'home.events.summit.title',
    descTransKey: 'home.events.summit.desc',
    buttonHref: '',
    topImgs: [
      {
        url: '/images/events/developer/top_1.png',
        href: ''
      },
      {
        url: '/images/events/developer/top_2.png',
        href: ''
      },
      {
        url: '/images/events/developer/top_3.png',
        href: ''
      },
      {
        url: '/images/events/developer/top_4.png',
        href: ''
      }
    ],
    bottomImgs: [
      {
        url: '/images/events/developer/bottom_1.png',
        href: ''
      },
      {
        url: '/images/events/developer/bottom_2.png',
        href: ''
      },
      {
        url: '/images/events/developer/bottom_3.png',
        href: ''
      },
      {
        url: '/images/events/developer/bottom_4.png',
        href: ''
      }
    ]
  }
];

export function SectionEvents() {
  const { t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { isMIN, isSM, isMD } = useSize();
  const slidesCount = useMemo(() => {
    if (isMIN) {
      return 1;
    }
    if (isSM || isMD) {
      return 2;
    }
    return 3;
  }, [isMIN, isSM, isMD]);

  const renderSwiper = useCallback(
    (type: 'top' | 'bottom') => {
      const isTop = type === 'top';
      const data: ITabImg[] = [];
      if (isTop) {
        data.push(
          ...tabs[activeTabIndex].topImgs,
          ...tabs[activeTabIndex].topImgs
        );
      } else {
        data.push(
          ...tabs[activeTabIndex].bottomImgs,
          ...tabs[activeTabIndex].bottomImgs
        );
      }
      return (
        <div className={cn('relative mt-8')}>
          <Swiper
            spaceBetween={24}
            modules={[Autoplay]}
            className="linear-swiper"
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
              pauseOnMouseEnter: false,
              reverseDirection: !isTop
            }}
            slidesPerView={slidesCount}
            loop={true}
            pagination={false}
            speed={10000}
          >
            {data.map((topImage, topIndex) => (
              <SwiperSlide
                key={topIndex}
                className="aspect-[2.3626] overflow-hidden rounded-xl bg-cover"
                style={{
                  backgroundImage: `url('${topImage.url}')`
                }}
              ></SwiperSlide>
            ))}
          </Swiper>
          <div>
            <div
              style={{
                width: `${100 / slidesCount / 2}%`
              }}
              className="absolute left-0 top-0 z-20 hidden h-full bg-[linear-gradient(90deg,#080a0f_0%,rgba(0,0,0,0)_100%)] lg:block"
            ></div>
            <div
              style={{
                width: `${100 / slidesCount / 2}%`
              }}
              className="absolute right-0 top-0 z-20 hidden h-full bg-[linear-gradient(270deg,#080a0f_0%,rgba(0,0,0,0)_100%)] lg:block"
            ></div>
          </div>
        </div>
      );
    },
    [activeTabIndex, slidesCount]
  );

  return (
    <div className="relative min-h-[600px] overflow-x-hidden">
      <div className="page-layout">
        <TitleLine
          title={t('home.events.title')}
          className="mb-4 mt-16 sm:mb-10 sm:mt-36"
          type="events"
          align="left"
        />
        <div className="flex w-full items-center gap-x-[6px] rounded bg-[#0f111f] px-2 py-1 sm:w-fit sm:gap-x-6 sm:px-8 sm:py-4">
          {tabs.map((tab, index) => {
            const isLast = index === tabs.length - 1;
            const isActive = index === activeTabIndex;
            return (
              <div
                key={index}
                className="flex items-center gap-x-[6px] sm:gap-x-6"
              >
                <div
                  className={cn(
                    'relative cursor-pointer break-words text-xs duration-200 sm:max-w-none sm:text-base',
                    isActive
                      ? 'font-medium text-white'
                      : 'font-medium text-[#9591AB]'
                  )}
                  onClick={() => {
                    setActiveTabIndex(index);
                  }}
                >
                  {t(tab.titleTransKey)}
                  <div
                    className={cn(
                      'absolute -bottom-[2px] left-0 hidden h-[2px] overflow-hidden rounded-full bg-[#8060FF] duration-200 sm:block',
                      isActive ? 'w-[100%]' : 'w-0'
                    )}
                  ></div>
                </div>
                {!isLast && (
                  <div className="-pr-4 mr-4 h-[11px] w-[1px] bg-[#9591AB] sm:mr-0 sm:pr-0"></div>
                )}
              </div>
            );
          })}
        </div>
        <p className="mb-0 mt-4 origin-top-left scale-[0.85] text-xs leading-[1.5] text-[#9591AB] sm:mb-3 sm:mt-8 sm:w-[80%] sm:scale-100 sm:text-base">
          {t(tabs[activeTabIndex]?.descTransKey)}
        </p>
        <Button
          onClick={() => {
            window.open('https://chainide.com/events/', '_blank');
          }}
          text={t('home.events.button.text')}
          className="h-11 w-[186px]"
        />
        {renderSwiper('top')}
        {renderSwiper('bottom')}
      </div>
      <div className="absolute -right-[60%] -top-8 -z-[10] origin-right scale-[0.75] sm:right-0 sm:top-8 4xl:scale-100">
        <StaticImage
          alt="earth"
          src="../../../assets/images/sections/fifth_earth.png"
          width={490}
          height={299}
        />
      </div>
    </div>
  );
}
