import { Link } from 'gatsby';
import React, { useMemo, useRef, useState } from 'react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import ArrowImg from '@/assets/images/sections/stories_arrow.inline.svg';
import { useTranslation } from '@/i18n';
import { useSize } from '@/utils/hooks';

import { TitleLine } from './TitleLine';

const cards = [
  {
    titleTransKey: 'home.stories.mw.title',
    descTransKey: 'home.stories.mw.desc',
    bgUrl: '/images/stories_matrix_world_bg.png',
    href: 'https://matrixworld.org'
  },
  {
    titleTransKey: 'home.stories.mm.title',
    descTransKey: 'home.stories.mm.desc',
    bgUrl: '/images/stories_matrix_market_bg.png',
    href: 'https://matrixmarket.xyz'
  },
  {
    titleTransKey: 'home.stories.pb.title',
    descTransKey: 'home.stories.pb.desc',
    bgUrl: '/images/stories_phanta_bear_bg.png',
    href: 'https://ezek.io'
  },
  {
    titleTransKey: 'home.stories.rm.title',
    descTransKey: 'home.stories.rm.desc',
    bgUrl: '/images/stories_rivermen_bg.png',
    href: 'https://rivermen.io'
  },
  {
    titleTransKey: 'home.stories.ts.title',
    descTransKey: 'home.stories.ts.desc',
    bgUrl: '/images/stories_theirsverse_bg.png',
    href: 'https://theirsverse.com'
  },
  {
    titleTransKey: 'home.stories.gld.title',
    descTransKey: 'home.stories.gld.desc',
    bgUrl: '/images/stories_grandline_bg.png',
    href: 'https://grandlinedao.xyz'
  }
];
const cardAmount = cards.length;
const swiperBetween = 24;
export function SectionStories() {
  const { t } = useTranslation();
  const [swiperIns, setSwiperIns] = useState<SwiperClass | null>(null);
  const panigationRef = useRef<HTMLDivElement | null>(null);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const { isMIN, isSM, isMD } = useSize();

  const slidesCount = useMemo(() => {
    if (isMIN) {
      return 1;
    }
    if (isSM || isMD) {
      return 2;
    }
    return 3;
  }, [isMIN, isSM, isMD]);
  return (
    <div className="page-layout min-h-[600px] pb-20">
      <TitleLine
        title={t('home.stories.title')}
        className="mb-12 mt-24 sm:mb-20 sm:mt-36"
        type="stories"
        align="center"
      />
      <div className="px-3 sm:px-0">
        <Swiper
          onSwiper={setSwiperIns}
          spaceBetween={swiperBetween}
          modules={[Autoplay, Pagination]}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false
          }}
          onSlideChange={(swiper) => {
            setSwiperIns(swiper);
            setSwiperIndex(swiper.realIndex % cardAmount);
          }}
          slidesPerView={slidesCount}
          loop={true}
          pagination={false}
        >
          {[...cards, ...cards].map((card, index) => (
            <SwiperSlide
              key={index}
              className="flex aspect-[0.7137] items-center justify-center overflow-hidden rounded-xl bg-cover px-0"
            >
              <a href={card.href || '/'} target="_blank">
                <div
                  className="aspect-[0.7137] w-full bg-cover px-5 py-[23px]"
                  style={{
                    backgroundImage: `url('${card.bgUrl}')`,
                    maxWidth: `calc((100% - ${
                      (slidesCount - 1) * swiperBetween
                    }) / ${slidesCount})px`
                  }}
                >
                  <div>
                    <div className="-mb-3 flex origin-top-left scale-75 items-center gap-x-2 sm:mb-0 sm:scale-100">
                      <h3 className="text-2xl font-semibold leading-[60px]">
                        {t(card.titleTransKey)}
                      </h3>
                      <ArrowImg />
                    </div>
                    <p className="whitespace-pre-line text-sm leading-[22px] text-[#FFFFFFB2] sm:text-base">
                      {t(card.descTransKey)}
                    </p>
                  </div>
                </div>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div
        className="mt-3 cursor-pointer py-4 sm:mt-6"
        ref={panigationRef}
        onClick={(event) => {
          if (panigationRef.current && swiperIns) {
            const totalWidth = panigationRef.current.clientWidth;
            const clickX =
              event.clientX -
              panigationRef.current.getBoundingClientRect().left;
            // 将总宽度分成6等分块
            const blockSize = totalWidth / cardAmount;
            // 计算点击位置位于第几块
            const blockNumber = Math.floor(clickX / blockSize);
            swiperIns?.slideToLoop(
              swiperIns.realIndex +
                (blockNumber - (swiperIns.realIndex % cardAmount))
            );
            setSwiperIndex(blockNumber);
          }
        }}
      >
        <div className="relative mx-3 h-[5px] w-[calc(100%_-_24px)] overflow-hidden rounded-full bg-[#10141E] sm:mx-0 sm:w-full">
          <div
            className="absolute left-0 h-full rounded-full bg-[linear-gradient(90deg,#8060FF_0%,#8060FF_60.68%,#BEFF35_108.22%)] duration-200"
            style={{
              width: `calc((100%) / ${cardAmount})`,
              transform: `translateX(${100 * swiperIndex}%)`
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
