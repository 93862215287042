import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import { useTranslation } from '@/i18n';
import { useHeaderHeight } from '@/utils/hooks';

import { ChainSwiper } from './ChainSwiper';

const staticsData = [
  {
    metricsTransKey: 'home.first.projects',
    value: '250,000+'
  },
  {
    metricsTransKey: 'home.first.contracts',
    value: '5,000,000+'
  },
  {
    metricsTransKey: 'home.first.developers',
    value: '300,000+'
  },
  {
    metricsTransKey: 'home.first.increase',
    value: '13'
  }
];
export function SectionFirstScreen() {
  const { t } = useTranslation();
  const { headerHeightValue } = useHeaderHeight();
  return (
    <div
      className="flex h-screen justify-center bg-[url('/images/bg_mobile.png')] bg-cover bg-center lg:bg-[url('/images/bg.png')]"
      style={{
        marginTop: `-${headerHeightValue}px`,
        paddingTop: `${headerHeightValue}px`
      }}
    >
      <div
        className="relative w-full"
        style={{
          height: `calc(100vh - ${headerHeightValue}px)`
        }}
      >
        <div className="page-layout">
          <div
            className="absolute top-[50%] -translate-y-[calc(50%_+_88px)]"
            style={{
              transform: `translateY(calc(50% + ${headerHeightValue}px) * -1)`
            }}
          >
            <h1 className="mb-5 text-[34px] font-semibold leading-[1.23] sm:text-[45px] xl:text-[68px] 4xl:max-w-[1200px] 4xl:text-[100px]">
              {t('common.website.slogan')}
            </h1>
            <p className="font-medium sm:text-lg sm:leading-8 xl:text-xl xl:leading-10 4xl:max-w-[1200px] 4xl:text-3xl 4xl:leading-[48px]">
              {t('common.website.tagline')}
            </p>
          </div>

          <div className="absolute right-[6vw] top-[28vh] hidden lg:block 4xl:right-[10vw]">
            <StaticImage
              src="../../../assets/images/sections/first_earth.png"
              alt="earth"
              width={213}
              height={185}
              className="-z-[1] hidden origin-center lg:block lg:scale-125 4xl:scale-[1.5]"
            />
          </div>
        </div>

        <div className="absolute bottom-2 left-0 w-full lg:bottom-4">
          <div className="page-layout flex flex-row flex-wrap items-start justify-between gap-y-2 ">
            {staticsData.map((item) => (
              <div key={item.metricsTransKey} className="w-[50%] sm:w-auto">
                <h3 className="mb-2 text-lg font-semibold text-[#9885FF] sm:mb-[14px] sm:text-xl lg:text-[36px] lg:leading-7 4xl:text-[40px]">
                  {item.value}
                </h3>
                <p className="text-sm font-medium leading-5 text-[#ABA7BE] sm:text-sm 4xl:text-base">
                  {t(item.metricsTransKey)}
                </p>
              </div>
            ))}
          </div>
          <div className="page-layout mt-10 h-12 bg-[linear-gradient(90deg,rgba(128,96,255,0.00)_0%,rgba(128,96,255,0.17)_49.99%,rgba(128,96,255,0.00)_96.53%)] sm:mt-14 sm:h-[72px]">
            <ChainSwiper />
          </div>
        </div>
      </div>
    </div>
  );
}
