import React, { useEffect, useState } from 'react';

import AntImg from '@/assets/images/common/chain/ant.inline.svg';
import AptosImg from '@/assets/images/common/chain/aptos.inline.svg';
import AstarImg from '@/assets/images/common/chain/astar.inline.svg';
import BinanceImg from '@/assets/images/common/chain/binance.inline.svg';
import Chain33Img from '@/assets/images/common/chain/chain33.inline.svg';
import ConfluxImg from '@/assets/images/common/chain/conflux.inline.svg';
import DfinityImg from '@/assets/images/common/chain/dfinity.inline.svg';
import EthereumImg from '@/assets/images/common/chain/ethereum.inline.svg';
import FiscoImg from '@/assets/images/common/chain/fisco.inline.svg';
import FlowImg from '@/assets/images/common/chain/flow.inline.svg';
import NearImg from '@/assets/images/common/chain/near.inline.svg';
import PolygonImg from '@/assets/images/common/chain/polygon.inline.svg';
import XdcImg from '@/assets/images/common/chain/xdc.inline.svg';

const chains = [
  {
    name: 'BNB Chain',
    img: <BinanceImg />
  },
  {
    name: 'Ethereum',
    img: <EthereumImg />
  },
  {
    name: 'Flow',
    img: <FlowImg />
  },
  {
    name: 'Polygon',
    img: <PolygonImg />
  },
  {
    name: 'Dfinity',
    img: <DfinityImg />
  },
  {
    name: 'Aptos',
    img: <AptosImg />
  },
  {
    name: 'Near',
    img: <NearImg />
  },
  {
    name: 'XDC',
    img: <XdcImg />
  },
  {
    name: 'FISCO BCOS',
    img: <FiscoImg />
  },
  {
    name: 'Conflux',
    img: <ConfluxImg />
  },
  {
    name: 'Chain33',
    img: <Chain33Img />
  },
  {
    name: 'AntChain',
    img: <AntImg />
  },
  {
    name: 'Astar',
    img: <AstarImg width={32} height={32} />
  }
];

const SCROLL_SPEED = 1; // 调整滚动速度
const seamlessScrollId = 'horizontal-seamless-scroll';
export function ChainSwiper() {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const container = document.getElementById(seamlessScrollId);
    let animationFrameId: number;

    const startAnimation = () => {
      if (!isHovered && container) {
        container.scrollLeft += SCROLL_SPEED;
        if (
          container.scrollLeft >=
          container.scrollWidth - container.clientWidth
        ) {
          container.scrollLeft = 0;
        }
        animationFrameId = requestAnimationFrame(startAnimation);
      }
    };

    const handleMouseEnter = () => {
      setIsHovered(true);
      cancelAnimationFrame(animationFrameId);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
      requestAnimationFrame(startAnimation);
    };

    container?.addEventListener('mouseenter', handleMouseEnter);
    container?.addEventListener('mouseleave', handleMouseLeave);

    requestAnimationFrame(startAnimation);

    return () => {
      container?.removeEventListener('mouseenter', handleMouseEnter);
      container?.removeEventListener('mouseleave', handleMouseLeave);
      cancelAnimationFrame(animationFrameId);
    };
  }, [isHovered]);
  return (
    <div
      id={seamlessScrollId}
      className="flex h-full w-full items-center gap-x-8 overflow-y-auto overflow-x-hidden sm:gap-x-[63px]"
    >
      {[...chains, ...chains, ...chains, ...chains, chains[0]].map(
        (chain, index) => (
          <div
            key={index}
            className="group relative origin-center scale-75 cursor-pointer sm:scale-100"
            title={chain.name}
          >
            {chain.img}
            {/* <div className="absolute -top-8 left-[50%] z-[10] hidden -translate-x-[50%] whitespace-nowrap rounded bg-[#FFFFFF21] px-[10px] py-[7px] text-xs text-[#8060FF] group-hover:flex">
              {chain.name}
            </div> */}
          </div>
        )
      )}
    </div>
  );
}
