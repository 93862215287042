import cn from 'classnames';
import React, { useMemo } from 'react';

import { useTranslation } from '@/i18n';

interface ITitleLineProps {
  title: string;
  type?: 'learning' | 'coding' | 'events' | 'future' | 'stories';
  className?: string;
  align?: 'left' | 'center';
}
export function TitleLine(props: ITitleLineProps) {
  const { title, type, align = 'center', className } = props;
  const { t } = useTranslation();
  const desc = useMemo(() => {
    switch (type) {
      case 'coding':
        return t('home.studio.desc');
      case 'learning':
        return t('home.edu.desc');
      case 'events':
        return t('home.events.desc');
      case 'future':
        return t('home.future.desc');
      case 'stories':
        return t('home.stories.desc');
      default:
        break;
    }
  }, [t, type]);
  return (
    <div className={cn(className)}>
      <p
        className={cn(
          'w-fit bg-clip-text text-base font-semibold text-fill-transparent',
          align === 'center' && 'mx-auto',
          type === 'learning' &&
            'bg-[linear-gradient(90deg,#8060FF_54.6%,#BEFF35_68%)]',
          type === 'coding' &&
            'bg-[linear-gradient(90deg,#8060FF_51.38%,#BEFF35_62.27%)]',
          type === 'events' &&
            'bg-[linear-gradient(90deg,#8060FF_56.83%,#BEFF35_76.24%)]',
          type === 'future' &&
            'bg-[linear-gradient(90deg,#8060FF_4.43%,#8060FF_59.8%,#BEFF35_78.57%)]',
          type === 'stories' &&
            'bg-[linear-gradient(90deg,#8060FF_7.4%,#8060FF_78.48%,#BEFF35_88.65%)]'
        )}
      >
        {desc}
      </p>
      <h2
        className={cn(
          'text-3xl font-semibold leading-normal sm:text-[40px]',
          align === 'center' && 'text-center',
          type && 'mt-2'
        )}
      >
        {title}
      </h2>
    </div>
  );
}
