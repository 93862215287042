import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import StudioIDEImg from '@/assets/images/sections/studio_ide.inline.svg';
type CodeCharType = 'comment' | 'keywords' | 'normal' | 'version' | 'lineNum';
interface ICodeChar {
  content: ReactNode;
  type?: CodeCharType;
}
const breakLine: ICodeChar = {
  content: <br />
};
const semicolon: ICodeChar = {
  content: ';',
  type: 'normal'
};
function generateLineNumberBlock(lineNum: number) {
  return {
    content: `${lineNum}  `,
    type: 'lineNum' as CodeCharType
  };
}
const MapCodeCharTypeToColor: Record<CodeCharType, string> = {
  comment: '#608b4e',
  keywords: '#569cd6',
  normal: '#d4d4d4',
  version: '#b5cea8',
  lineNum: '#858585'
};
const codeCharArray: ICodeChar[] = [
  generateLineNumberBlock(1),
  ...getWordCharCodeArray(' // SPDX-License-Identifier: MIT', 'comment'),
  breakLine,
  generateLineNumberBlock(2),
  ...getWordCharCodeArray(' pragma solidity ', 'keywords'),
  ...getWordCharCodeArray('^', 'normal'),
  ...getWordCharCodeArray('0.8.0', 'version'),
  semicolon,
  breakLine,
  generateLineNumberBlock(3),
  breakLine,
  generateLineNumberBlock(4),
  ...getWordCharCodeArray(' contract ', 'keywords'),
  ...getWordCharCodeArray('Storage {', 'normal'),
  breakLine,
  generateLineNumberBlock(5),
  ...getWordCharCodeArray('    uint256 ', 'keywords'),
  ...getWordCharCodeArray('number', 'normal'),
  semicolon,
  breakLine,
  generateLineNumberBlock(6),
  breakLine,
  generateLineNumberBlock(7),
  ...getWordCharCodeArray('    function ', 'keywords'),
  ...getWordCharCodeArray('store(', 'normal'),
  ...getWordCharCodeArray('uint256 ', 'keywords'),
  ...getWordCharCodeArray('num) ', 'normal'),
  ...getWordCharCodeArray('public ', 'keywords'),
  ...getWordCharCodeArray('{', 'normal'),
  breakLine,
  generateLineNumberBlock(8),
  ...getWordCharCodeArray('        number = num;', 'normal'),
  breakLine,
  generateLineNumberBlock(9),
  ...getWordCharCodeArray('    }', 'normal'),
  breakLine,
  generateLineNumberBlock(10),
  breakLine,
  generateLineNumberBlock(11),
  ...getWordCharCodeArray('   function ', 'keywords'),
  ...getWordCharCodeArray('retrieve() ', 'normal'),
  ...getWordCharCodeArray('public view returns ', 'keywords'),
  ...getWordCharCodeArray('(', 'normal'),
  ...getWordCharCodeArray('uint256', 'keywords'),
  ...getWordCharCodeArray(') {', 'normal'),
  breakLine,
  generateLineNumberBlock(12),
  ...getWordCharCodeArray('        return ', 'keywords'),
  ...getWordCharCodeArray('number;', 'normal'),
  breakLine,
  generateLineNumberBlock(13),
  ...getWordCharCodeArray('   }', 'normal'),
  breakLine,
  generateLineNumberBlock(14),
  ...getWordCharCodeArray('}', 'normal')
];
export function DynamicCodeBlock() {
  const [index, setIndex] = useState(0);
  const indexRef = useRef(0);
  const { ref: containerRef, inView: containerInView } = useInView();
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (indexRef.current >= codeCharArray.length) {
        clearInterval(intervalId);
      } else {
        indexRef.current += 1;
        setIndex(indexRef.current);
      }
    }, 20);
    return () => {
      clearInterval(intervalId);
    };
  }, [containerInView]);
  useEffect(() => {
    setIndex(0);
    indexRef.current = 0;
  }, [containerInView]);
  return (
    <div ref={containerRef}>
      {containerInView && (
        <div>
          <StudioIDEImg className="mb-2 origin-left scale-[0.7] sm:mb-4 sm:ml-3 lg:scale-[0.9] xl:scale-100" />
          <pre className="h-[224px] text-xs sm:h-[280px] sm:text-sm">
            {codeCharArray.slice(0, index).map((item) => (
              <span
                style={{
                  color: MapCodeCharTypeToColor[item.type || 'normal'],
                  userSelect: item.type === 'lineNum' ? 'none' : 'auto'
                }}
              >
                {item.content}
              </span>
            ))}
          </pre>
        </div>
      )}
    </div>
  );
}

function strToCharArray(value: string) {
  return [...value];
}

function getWordCharCodeArray(words: string, type: CodeCharType) {
  return strToCharArray(words).map((item) => ({
    content: item,
    type: type
  }));
}
