import cn from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import { useTranslation } from '@/i18n';

const data = [
  {
    titleTransKey: 'home.intro.learn.title',
    descTransKey: 'home.intro.learn.desc',
    colorCls: 'bg-[linear-gradient(90deg,#6E53E8_0%,rgba(110,83,232,0.00)_90%)]'
  },
  {
    titleTransKey: 'home.intro.build.title',
    descTransKey: 'home.intro.build.desc',
    colorCls:
      'bg-[linear-gradient(90deg,#BEFF35_0.16%,rgba(190,255,53,0.00)_96.12%)]'
  },
  {
    titleTransKey: 'home.intro.join.title',
    descTransKey: 'home.intro.join.desc',
    colorCls:
      'bg-[linear-gradient(90deg,#FD73B3_0%,rgba(253,115,179,0.00)_92.5%)]'
  }
];
export function SectionIntro() {
  const { t } = useTranslation();
  return (
    <div className="page-layout mt-10 flex min-h-[480px] flex-col text-white sm:mt-24 lg:flex-row 4xl:mt-32">
      {/* left */}
      <div className="w-full lg:w-[50%]">
        <h2 className="max-w-[500px] text-2xl font-semibold leading-[1.5] sm:text-[40px] xl:text-[50px] 4xl:max-w-none">
          {t('home.intro.title')}
        </h2>
        <StaticImage
          alt="mars"
          width={503}
          height={404}
          src="../../../assets/images/sections/second_mars.png"
          class="mx-auto"
        />
      </div>
      {/* right */}
      <div className="flex w-full flex-col gap-y-8 pt-0 sm:gap-y-14 sm:pt-20 lg:w-[46%]">
        {data.map((item) => (
          <div key={item.titleTransKey}>
            <h3 className="relative text-xl font-semibold leading-normal sm:text-[32px]">
              <div
                className={cn(
                  'absolute left-0 top-[17px] -z-[1] h-[10px] w-10 rounded-[1px] sm:top-7',
                  item.colorCls
                )}
              ></div>
              <span className="z-[1] pl-1 sm:pl-3">
                {t(item.titleTransKey)}
              </span>
            </h3>
            <span className="font-Roboto text-[#A3A8B5] sm:text-xl">
              {t(item.descTransKey)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
