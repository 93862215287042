import cn from 'classnames';
import Lottie from 'lottie-react';
import React from 'react';
import { useInView } from 'react-intersection-observer';

interface IUseLottieInViewProps {
  animationData: unknown;
  className?: string;
  inViewCheck?: boolean;
}
export function LottieInView(props: IUseLottieInViewProps) {
  const { animationData, className, inViewCheck = true } = props;
  const { ref: containerRef, inView: containerInView } = useInView();
  if (inViewCheck) {
    return (
      <div className={cn(className)} ref={containerRef}>
        {containerInView && (
          <Lottie animationData={animationData} autoplay={true} loop={false} />
        )}
      </div>
    );
  }
  return <Lottie animationData={animationData} autoplay={true} loop={false} />;
}
