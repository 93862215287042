import cn from 'classnames';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';

import ArrowImg from '@/assets/images/common/header/arrow.inline.svg';
import { useTranslation } from '@/i18n';

export const menus = [
  {
    titleTransKey: 'menu.learn',
    subs: [
      {
        subTitleTransKey: 'menu.education',
        href: 'https://edu.chainide.com'
      },
      {
        subTitleTransKey: 'menu.courses',
        href: 'https://edu.chainide.com/courses'
      }
    ]
  },
  {
    titleTransKey: 'menu.build',
    subs: [
      {
        subTitleTransKey: 'menu.studio',
        href: 'http://chainide.com/'
      },
      {
        subTitleTransKey: 'menu.ext',
        href: ''
      }
    ]
  },
  {
    titleTransKey: 'menu.eco',
    subs: [
      {
        subTitleTransKey: 'menu.events',
        href: 'https://chainide.com/events/'
      },
      {
        subTitleTransKey: 'menu.bootcamp',
        href: 'https://chainide.com/events/bootcamp'
      },
      {
        subTitleTransKey: 'menu.hackathon',
        href: 'https://chainide.com/events/hackathon'
      },
      {
        subTitleTransKey: 'menu.workshop',
        href: 'https://chainide.com/events/workshop-seminar'
      },
      {
        subTitleTransKey: 'menu.summit',
        href: 'https://chainide.com/events/developer-summit'
      }
    ]
  },
  {
    titleTransKey: 'menu.community',
    subs: [
      {
        subTitleTransKey: 'menu.forum',
        href: 'https://forum.chainide.com/'
      },
      {
        subTitleTransKey: 'menu.discord',
        href: 'https://discord.com/invite/qv8M29pYwX'
      },
      {
        subTitleTransKey: 'menu.twitter',
        href: 'https://twitter.com/ChainIde'
      },
      {
        subTitleTransKey: 'menu.telegram',
        href: 'https://t.me/ChainIDEdevelopers'
      }
    ]
  },
  {
    titleTransKey: 'menu.resources',
    subs: [
      {
        subTitleTransKey: 'menu.blog',
        href: 'https://medium.com/white-matrix'
      },
      {
        subTitleTransKey: 'menu.guides',
        href: 'https://chainide.gitbook.io/chainide-chinese/chainide/1.-ethereum-ide'
      },
      {
        subTitleTransKey: 'menu.docs',
        href: 'https://chainide.gitbook.io/chainide-english-1/'
      },
      {
        subTitleTransKey: 'menu.videos',
        href: 'https://www.youtube.com/channel/UCgvPUHayWfxAGiJCI2xOzNg'
      },
      {
        subTitleTransKey: 'menu.osc',
        href: 'https://github.com/WhiteMatrixTech'
      }
    ]
  }
];

export function PCMenu() {
  const { t } = useTranslation();
  const [activeMenu, setActiveMenu] = useState('');
  const [activeSubMenu, setActiveSubMenu] = useState('');
  const [subMenuMaxHeight, setSubMenuMaxHeight] = useState(0);
  useEffect(() => {
    if (activeMenu) {
      setSubMenuMaxHeight(
        document.getElementById(`menu-${activeMenu}`)?.scrollHeight || 0
      );
    } else {
      setActiveMenu('');
    }
  }, [activeMenu]);
  return (
    <div className="hidden items-center gap-x-9 text-lg font-normal text-[#EEFFFC] lg:flex 2xl:gap-x-[43px]">
      {menus.map((menu) => (
        <div
          key={menu.titleTransKey}
          onMouseEnter={() => {
            setActiveMenu(menu.titleTransKey);
          }}
          onClick={() => {
            if (menu.titleTransKey === activeMenu) {
              setActiveMenu('');
            } else {
              setActiveMenu(menu.titleTransKey);
            }
          }}
          onMouseLeave={() => {
            setActiveMenu('');
          }}
          className="relative flex h-16 cursor-pointer items-center lg:h-[88px]"
        >
          <div className="flex items-center gap-x-1">
            <span>{t(menu.titleTransKey)}</span>
            <ArrowImg
              className={cn(
                '!w-3 duration-200',
                activeMenu === menu.titleTransKey ? 'rotate-180' : 'rotate-0'
              )}
            />
          </div>
          <div
            className={cn(
              'absolute left-[50%] top-[70px] flex w-[175px] -translate-x-[50%] flex-col justify-between overflow-hidden rounded-lg border-solid border-[#292738] bg-[#0E0D1B] text-base leading-7 text-[#EEFFFC] duration-200',
              menu.titleTransKey === activeMenu ? 'border-[1px]' : 'border-none'
            )}
            id={`menu-${menu.titleTransKey}`}
            style={{
              maxHeight:
                menu.titleTransKey === activeMenu
                  ? `${subMenuMaxHeight}px`
                  : '0px'
            }}
          >
            {menu.subs.map((sub, index) => {
              const isFirst = index === 0;
              const isLast = index === menu.subs.length - 1;
              return (
                <button
                  onMouseEnter={() => {
                    setActiveSubMenu(sub.subTitleTransKey);
                  }}
                  onClick={() => {
                    if (sub.href.includes('http')) {
                      window.open(sub.href, '_self');
                    } else {
                      navigate(sub.href);
                    }
                    setActiveMenu('');
                    setActiveSubMenu('');
                  }}
                  key={sub.subTitleTransKey}
                  className={cn(
                    'mx-[10px] my-[2px] rounded-lg px-[5px] py-1 text-center',
                    isFirst && 'mt-[14px]',
                    isLast && 'mb-[14px]',
                    activeSubMenu === sub.subTitleTransKey && 'bg-[#201e31]'
                  )}
                >
                  {t(sub.subTitleTransKey)}
                </button>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}
