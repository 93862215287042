import cn from 'classnames';
import React from 'react';

import RightArrow from '@/assets/images/common/right-arrow.inline.svg';
interface IButtonProps {
  className?: string;
  onClick?: () => void;
  text: string;
}
export function Button(props: IButtonProps) {
  const { className, onClick, text } = props;
  return (
    <button
      onClick={() => {
        onClick && onClick();
      }}
      type="button"
      className={cn(
        className,
        'flex origin-left scale-75 items-center justify-center gap-x-[2px] rounded-xl bg-[#8060FF] text-lg font-medium text-white duration-200 hover:opacity-75 sm:scale-100'
      )}
    >
      <span>{text}</span>
      <RightArrow />
    </button>
  );
}
