import cn from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import eduConversationLottie from '@/assets/lottie/education_coversation.json';
import eduSplitLottie from '@/assets/lottie/education_split.json';
import eduTogetherLottie from '@/assets/lottie/education_together.json';
import eduVideoLottie from '@/assets/lottie/education_video.json';
import { Button } from '@/components';
import { LottieInView } from '@/components/common/LottieInView';
import { useTranslation } from '@/i18n';

import { TitleLine } from './TitleLine';

const data = [
  {
    titleTransKey: 'home.edu.conversation.title',
    descTransKey: 'home.edu.conversation.desc',
    element: <LottieInView animationData={eduConversationLottie} />
  },
  {
    titleTransKey: 'home.edu.split.title',
    descTransKey: 'home.edu.split.desc',
    element: <LottieInView animationData={eduSplitLottie} />
  },
  {
    titleTransKey: 'home.edu.video.title',
    descTransKey: 'home.edu.video.desc',
    element: <LottieInView animationData={eduVideoLottie} />
  },
  {
    titleTransKey: 'home.edu.together.title',
    descTransKey: 'home.edu.together.desc',
    element: <LottieInView animationData={eduTogetherLottie} />
  }
];
export function SectionEducation() {
  const { t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [swiperRef, setSwiperRef] = useState<SwiperClass | null>(null);
  return (
    <div className="page-layout relative">
      <TitleLine
        title={t('home.edu.title')}
        className="mb-12 mt-20 sm:mb-20 sm:mt-40"
        type="learning"
      />
      <div className="flex w-full items-center gap-x-[7vw]">
        <div className="relative flex w-full flex-col gap-y-6 sm:gap-y-[32px] lg:w-[calc((100%_-_7vw)_/_2)]">
          {data.map((item, index) => (
            <div key={item.titleTransKey}>
              <div
                className={cn(
                  'box-border flex h-[110px] cursor-pointer flex-col justify-center py-[11px] sm:px-4'
                )}
                onClick={() => {
                  setActiveTabIndex(index);
                  if (swiperRef) {
                    // 如果 loop 为 false，那么用 slideTo 方法，如果 loop 为 true，那么用 slideToLoop 方法
                    swiperRef.slideToLoop(
                      swiperRef.realIndex +
                        (index - (swiperRef.realIndex % data.length))
                    );
                  }
                }}
              >
                <h3 className="mb-2 text-lg font-semibold sm:text-2xl">
                  {t(item.titleTransKey)}
                </h3>
                <p className="text-sm text-[#9591AB] sm:text-base">
                  {t(item.descTransKey)}
                </p>
              </div>
              <div className="-mx-4 aspect-[1.657] sm:mx-4 lg:hidden">
                {item.element}
              </div>
            </div>
          ))}
          <Button
            text={t('home.edu.button.text')}
            className="h-11 w-[246px] sm:ml-4"
            onClick={() => {
              window.open('https://edu.chainide.com', '_blank');
            }}
          />
          <div
            className={cn(
              'absolute inset-x-0 left-0 top-0 -z-[1] hidden h-[110px] translate-y-0 rounded-lg bg-[#100F22] duration-200 lg:block'
            )}
            style={{
              transform: `translateY(${activeTabIndex * 142}px)`
            }}
          ></div>
        </div>
        <div className="relative hidden w-full sm:gap-y-[42px] lg:block lg:w-[calc((100%_-_7vw)_/_2)]">
          <Swiper
            onSwiper={setSwiperRef}
            modules={[Autoplay]}
            loop={true}
            autoplay={{
              disableOnInteraction: false,
              delay: 1500
            }}
            spaceBetween={16}
            onSlideChange={(swiper) => {
              setActiveTabIndex(swiper.realIndex % data.length);
            }}
            speed={1500}
          >
            {data.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="aspect-[1.657] max-w-[800px]">
                  {item.element}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="absolute -right-[60%] -top-8 hidden origin-right scale-[0.75] sm:-right-[13%] sm:top-12 lg:block 4xl:top-5 4xl:scale-[0.85]">
        <StaticImage
          alt="earth"
          src="../../../assets/images/sections/education_earth.png"
          width={672}
          height={528}
        />
      </div>
    </div>
  );
}
