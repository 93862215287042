import cn from 'classnames';
import { navigate } from 'gatsby';
import React, { useEffect, useMemo, useState } from 'react';

import ArrowImg from '@/assets/images/common/header/arrow.inline.svg';
import CloseImg from '@/assets/images/common/header/close.inline.svg';
import MenuImg from '@/assets/images/common/header/menu.inline.svg';
import { switchLanguage, useTranslation } from '@/i18n';

import { menus } from './PCMenu';

export function MobileMenu() {
  const { t } = useTranslation();
  const [showSub, setShowSub] = useState(false);
  const [activeMenuTitle, setActiveMenuTitle] = useState('');
  const [subMenuMaxHeight, setSubMenuMaxHeight] = useState(0);

  const mobileMenus = useMemo(
    () => [
      ...menus,
      {
        titleTransKey: 'menu.languages',
        subs: [
          {
            subTitleTransKey: 'menu.languages.en',
            click: () => {
              switchLanguage('en-US');
            }
          },
          {
            subTitleTransKey: 'menu.languages.zh',
            click: () => {
              switchLanguage('zh');
            }
          }
        ]
      }
    ],
    []
  );

  useEffect(() => {
    if (activeMenuTitle) {
      setSubMenuMaxHeight(
        document.getElementById(`mobile-menu-${activeMenuTitle}`)
          ?.scrollHeight || 0
      );
    } else {
      setActiveMenuTitle('');
    }
  }, [activeMenuTitle]);
  return (
    <div className="relative block font-Roboto lg:hidden">
      {showSub ? (
        <CloseImg
          onClick={() => {
            setShowSub(false);
          }}
          className="fixed right-4 top-4 z-[1] cursor-pointer"
        />
      ) : (
        <MenuImg
          onClick={() => {
            setShowSub(true);
          }}
          className="cursor-pointer"
        />
      )}
      <div
        className={cn(
          'fixed inset-x-0 top-0 -z-0 flex flex-col gap-y-[23px] overflow-hidden overflow-y-auto bg-[#0D1018] px-6 text-[#EEFFFC] duration-500',
          showSub ? 'h-screen' : 'h-0'
        )}
      >
        {mobileMenus.map((menu, menuIndex) => {
          const isFirstMenu = menuIndex === 0;
          const isLastMenu = menuIndex === mobileMenus.length - 1;
          return (
            <div
              key={menu.titleTransKey}
              className={cn(
                isFirstMenu && 'mt-[90px]',
                isLastMenu && 'mb-[26px]'
              )}
            >
              <div
                onClick={() => {
                  if (menu.titleTransKey === activeMenuTitle) {
                    setActiveMenuTitle('');
                  } else {
                    setActiveMenuTitle(menu.titleTransKey);
                  }
                }}
                className="flex items-center gap-x-1 text-base"
              >
                <span>{t(menu.titleTransKey)}</span>
                <ArrowImg
                  className={cn(
                    '!w-3 duration-200',
                    activeMenuTitle === menu.titleTransKey
                      ? 'rotate-180'
                      : 'rotate-0'
                  )}
                />
              </div>
              <div
                style={{
                  maxHeight:
                    menu.titleTransKey === activeMenuTitle
                      ? `${subMenuMaxHeight}px`
                      : '0px'
                }}
                id={`mobile-menu-${menu.titleTransKey}`}
                className="-mx-6 mt-2 flex flex-col overflow-hidden bg-[#24233380] duration-200"
              >
                {menu.subs.map((sub) => (
                  <div
                    key={sub.subTitleTransKey}
                    className={cn(
                      'block h-10 shrink-0 pl-9 pr-3 text-left text-sm leading-10'
                    )}
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      const { href, click } = sub as any;
                      if (href) {
                        if (href.includes('http')) {
                          window.open(href, '_self');
                        } else {
                          navigate(href);
                        }
                      } else if (click) {
                        click();
                      }
                      setActiveMenuTitle('');
                      setShowSub(false);
                    }}
                  >
                    {t(sub.subTitleTransKey)}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
